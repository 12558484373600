<template>
  <div v-if="showPopup && content" class="sales-popup-container">
    <div class="promo-signup">
      <svgicon
        class="close-circle-icon"
        name="close-circle"
        width="32px"
        height="32px"
        @click="closePopup"
      />
      <img
        class="bg-img"
        :src="getContentfulImgURL({ url: content.backgroundImage })"
        format="webp"
        loading="lazy"
        alt="early access signup background image"
      />
      <div class="blur-layer" />
      <div class="content">
        <div class="title">
          {{ content.title }}
        </div>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="desc" v-html="content.description" />
        <input v-model="email" class="email-input" placeholder="Your Email">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="terms" v-html="content.signUpAgreement" />
        <button
          class="promo-signup-btn"
          :class="{ disabled: loading }"
          @click="signup"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
    <div class="overlay" @click="closePopup" />
    <SignUpModal
      :show="showModal"
      :title="modalContent.title"
      :text="modalContent.text"
      :close="close"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { includesPage } from '@/helpers/route'
import imageOptimo from '~/mixins/imageOptimoMixin.js'

import SignUpModal from '~/components/utils/SignUpModal'

const formNameKey = 'woi-signupFormPopped'
const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+$/
export default {
  name: 'PromoSignup',
  components: {
    SignUpModal
  },
  mixins: [imageOptimo],
  data () {
    return {
      popupTimer: null,
      showPopup: false,
      loading: false,
      email: '',
      showModal: false,
      atagGroup: null,
      modalContent: {
        title: '',
        text: ''
      },
      modalSucContent: {
        title: 'Thank you for signing up.',
        text:
          'Keep an eye on your emails for early access to our biggest sale of the year. Happy travels!'
      },
      modalFailContent: {
        title: 'Sorry',
        text:
          'but you don’t seem to have entered a valid email address. Can you please check and try it again. Thanks'
      },
      modalWaitContent: {
        title: 'Sorry',
        text: 'Server error. Can you please check and try it again. Thanks'
      }
    }
  },
  computed: {
    ...mapGetters([
      'signupForm'
    ]),
    buttonText () {
      if (this.loading) {
        return 'Please wait...'
      }
      return this.content?.ctaButtonText
    }
  },
  watch: {
    $route (to, from) {
      this.listenToWindowOpen()
      this.setupPopupTimer()
    }
  },
  mounted () {
    // console.log('add event listener...')
    this.initSessionData()
    this.$nextTick(() => {
      this.listenToWindowOpen()
      const bd = document.querySelector('body')
      if (bd) {
        // console.log('reg mouseleave')
        bd.addEventListener('mouseleave', this.detectLeave)
      }
      this.setupPopupTimer()
    })
  },
  methods: {
    signup () {
      this.loading = true
      const url = this.content?.formLink
      // 'https://hornblowergroup.tfaforms.net/api_v2/workflow/processor'
      if (!url || !this.content?.formData) {
        this.modalContent = this.modalWaitContent
        this.resetLoading()
        this.showModal = true
        return
      }
      if (this.email.match(emailRegex)) {
        // add sign up request
        
        const formData = new FormData()
        const data = this.content.formData
        for (const key in data) {
          formData.append(key, data[key])
        }
        formData.delete('emailKey')
        // this field is required
        const emailKey = data.emailKey
        formData.append(emailKey, this.email)
        const requestOptions = {
          method: 'POST',
          body: formData,
          redirect: 'manual'
        }
        fetch(url, requestOptions)
          .then((response) => {
            this.modalContent = this.modalSucContent
          })
          .catch(() => {
            this.modalContent = this.modalFailContent
          })
          .finally(() => {
            this.resetLoading()
            this.showModal = true
          })
      } else {
        this.modalContent = this.modalFailContent
        this.resetLoading()
        this.showModal = true
      }
    },
    resetLoading () {
      this.loading = false
    },
    close () {
      this.showModal = false
    },
    closePopup () {
      this.showPopup = false
    },
    displayPopup () {
      this.showPopup = true
    },
    detectLeave () {
      // console.log('detect leave..')
      this.popupForm()
    },
    canPopup () {
      if (this.checkFormPopped()) {
        return false
      }
      return includesPage(this.content?.pagesToShow, this.$route.name)
    },
    setupPopupTimer () {
      clearTimeout(this.popupTimer)
      // console.log('setupPopupTimer...', this.content)
      if (!this.content) {
        setTimeout(() => this.setupPopupTimer(), 200)
      } else if (this.canPopup() && !this.popupTimer) {
        const timerMs = this.content.timer || 10
        const timer = timerMs * 1000
        this.popupTimer = setTimeout(() => {
          this.popupForm()
        }, timer)
        console.log('timer set ', timerMs, 's')
      } else {
        console.log('no need for timer..')
      }
    },
    popupForm () {
      clearTimeout(this.popupTimer)
      if (this.canPopup()) {
        this.displayPopup()
        this.setFormPopped({ formName: this.content.name, pageName: this.$route.name })
      }
    },
    checkFormPopped () {
      if (!this.content) {
        return true
      }
      const formsStr = sessionStorage.getItem(formNameKey)
      const formsMap = JSON.parse(formsStr) || {}
      const formName = this.content.name
      // console.log('checking formsMap:', formsMap)
      return formsMap[formName]
    },
    setFormPopped: ({ formName, pageName }) => {
      const formsStr = sessionStorage.getItem(formNameKey)
      const formsMap = JSON.parse(formsStr) || {}
      if (!formsMap[formName]) {
        formsMap[formName] = []
      }
      if (!formsMap[formName].includes(pageName)) {
        formsMap[formName].push(pageName)
        sessionStorage.setItem(formNameKey, JSON.stringify(formsMap))
      }
    },
    listenToWindowOpen () {
      if (this.atagGroup) {
        // console.log('remove all listeners...')
        this.atagGroup.forEach((atag) => {
          atag.removeEventListener('click', this.handleOpenWindow)
        })
      }
      this.atagGroup = document.querySelectorAll('a')
      this.atagGroup.forEach((atag) => {
        atag.addEventListener('click', this.handleOpenWindow)
      })
    },
    handleOpenWindow () {
      this.copySessionData()
    },
    copySessionData () {
      const formsStr = sessionStorage.getItem(formNameKey)
      localStorage.setItem(formNameKey, formsStr)
      setTimeout(() => localStorage.removeItem(formNameKey), 5000)
    },
    initSessionData () {
      const formsStr = localStorage.getItem(formNameKey)
      const formsStrFromSettion = sessionStorage.getItem(formNameKey)
      if (formsStr && !formsStrFromSettion) {
        sessionStorage.setItem(formNameKey, formsStr)
      }
      if (formsStr) {
        localStorage.removeItem(formNameKey)
      }
    }
  }
}
</script>
<style lang="scss">
.promo-signup {
  .terms p a {
    color: $woi-gold-main;
    &:hover {
      color: $woi-red-main;
    }
  }
}

</style>
<style lang="scss" scoped>
$bottom-m: 24px;
$base-z-index: 900;
.sales-popup-container {
  .overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: $base-z-index + 90;
    background: #ffffff;
    opacity: 0.5;
  }
}
.promo-signup {
  position: fixed;
  z-index: $base-z-index + 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1145px;
  height: 534px;
  filter: drop-shadow(0px 6px 18px rgba(0, 0, 0, 0.08));
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;

  .close-circle-icon {
    color: #ffffff;
    z-index: $base-z-index + 100;
    position: absolute;
    top: 16px;
    right: 16px;
    &:hover {
      cursor: pointer;
      color: $woi-gold-main;
    }
  }
  .bg-img {
    height: 100%;
    width: 100%;
    background-color: #443c47;
    position: absolute;
    z-index: $base-z-index+ 97;
    object-fit: cover;
    object-position: center;
  }
  .blur-layer {
    height: 100%;
    width: 50%;
    position: absolute;
    z-index: $base-z-index + 98;
    top: 0;
    right: 0;
    background: rgba(68, 60, 71, 0.5);
    /* Blur */
    backdrop-filter: blur(25px);
    border-radius: 0px 16px 16px 0px;
    @-moz-document url-prefix() {
      backdrop-filter: none !important;
    }
  }

  .content {
    z-index: $base-z-index + 99;
    width: 50%;
    padding: 48px 30px 0 30px;
    display: flex;
    flex-direction: column;
    > * {
      margin-bottom: $bottom-m;
    }
    .title {
      @extend %header-3;
      color: #ffffff;
    }
    .desc {
      @extend %header-5;
    }
    .terms {
      @extend %description-2;
    }
    .desc,
    .terms {
      color: #ffffff;
    }
    .email-input {
      width: 95%;
      height: 40px;
      background: #ffffff;
      border-radius: 8px;
    }
    .promo-signup-btn {
      @extend %subtitle-2-bold;
      height: 64px;
      width: 260px;
      border-radius: 90px;
      color: #f7f7f7;
      background-color: $woi-gold-main;
      &:focus {
        box-shadow: none;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
@include max-width($tablet) {
  .promo-signup {
    width: 95%;
    .content {
      padding: 38px 20px 0 20px;
      > * {
        margin-bottom: $bottom-m - 8px;
      }
      .title {
        @include header-4;
        text-align: left;
      }
      .promo-signup-btn {
        height: 48px;
      }
    }
  }
}

@include max-width($mobile-extra) {
  .promo-signup {
    width: 90%;
    height: unset;
    max-height: 460px;
    .blur-layer {
      width: 100%;
    }
    .close-circle-icon {
      top: 8px;
      right: 8px;
    }
    .content {
      width: 100%;
      padding: 18px 12px 0 12px;
      .title {
        @include header-5;
        text-align: left;
      }
      .desc {
        font-size: 24px !important;
        line-height: 32px !important;
      }
    }
    .promo-signup-btn {
      align-self: center;
    }
  }
}
</style>
